import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Figure from "../components/Figure"
import Section from "../components/Section"
import { H1, P } from "../components/VerticalRhythm"
import breakpoints from "../theme/breakpoints"

import PictureSet from "../components/PictureSet"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Figure id={"master"} caption="">
      {/* <PictureSet imgObj={} /> */}
    </Figure>
    <Section>
      <H1>Page not found</H1>
      <P>
        The page you specified was not found. You're welcome go back to the{" "}
        <Link to={"/"}> Home </Link> and look around there. Thank you very much.
      </P>
    </Section>
  </Layout>
)

export default NotFoundPage
